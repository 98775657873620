import { WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface EndorsableLoad {
  isTankerEndorsementRequired: boolean;
  isHazMat: boolean;
  isRegulatedByStf: boolean;
}

interface OwnProps {
  load: EndorsableLoad;
  separator?: string;
}

export const EndorsementList = ({ load, separator = ', ' }: OwnProps) => {
  const { t } = useTranslation();
  const endorsements = [];

  if (load.isTankerEndorsementRequired) {
    endorsements.push(t('TANKER'));
  }
  if (load.isHazMat) {
    endorsements.push(t('HazMat_No_Colon'));
  }
  if (load.isRegulatedByStf) {
    endorsements.push(t('STF'));
  }

  return (
    <span>
      {load.isRegulatedByStf && <WarningAmber style={{ marginRight: "2px", verticalAlign: "middle", color: "#B54D08", height: "19px" }} data-testid="stf-icon" />}
      {endorsements.join(separator)}
    </span>
  );
}